import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function ProjectThumnail(props) {
    const data = props.data;
    return (
        <div
            css={css`
                width: 980px;
                margin: 0 auto;
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                :hover {
                    background-color: #f8f8f8;
                }
            `}
        >
            <hr css={[divLine]} />
            <div
                css={css`
                    text-align: left;
                    line-height: 11px;
                    font-size: 11px;
                    margin-left: 5px;
                    display: flex;
                `}
            >
                <div
                    css={css`
                        width: 40px;
                    `}
                >
                    {data.no}
                </div>
                <div
                    css={css`
                        width: 351px;
                    `}
                >
                    {data.project}
                </div>
                <div
                    css={css`
                        width: 150px;
                        color: gray;
                    `}
                >
                    {data.title}
                </div>
                <div
                    css={css`
                        width: 150px;
                        color: gray;
                    `}
                >
                    {data.year}
                </div>
                <div
                    css={css`
                        width: 150px;
                        color: gray;
                    `}
                >
                    {data.use}
                </div>
                <div
                    css={css`
                        width: 130px;
                        color: gray;
                    `}
                >
                    {data.location}
                </div>
            </div>
            <hr css={[divLine]} />
        </div>
    );
}

export default ProjectThumnail;

const divLine = css`
    width: 100%;
    height: 1px;
    border: none;
    background-color: lightgray;
`;
