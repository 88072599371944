import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function NotFound() {
    return (
        <div
            css={css`
                margin: 0 auto;
                font-size: 10px;
                font-family: "NanumBarunGothic";
                text-align: center;
                height: 500px;
            `}
        >
            <h1>404 NotFound</h1>
        </div>
    );
}

export default NotFound;
