import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import data from "../news.json";

function News() {
    const newsletter = data.news.map((news, index) => {
        return (
            <div css={css``} key={index}>
                <hr
                    css={css`
                        width: 100%;
                        height: 1px;
                        border: none;
                        margin-top: 8px;
                        margin-bottom: 8px;
                        background-color: #e9e9e9;
                    `}
                />
                {news.content}
            </div>
        );
    });

    return (
        <div
            css={css`
                width: 980px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-family: "NanumBarunGothic";
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            `}
        >
            <Header />
            <div
                css={css`
                width:100%;
                margin: 0 auto;
                text-align: left;
                font-size: 12px;
                color: #565656;
                    -webkit-animation: fadein 1.5s;
                    @media screen and (max-width: 768px) {
                    width: 95%;
                   @keyframes fadein {
                     from {
                       opacity: 0;
                        }
                        to {
                            opacity: 1;
          };
          `}
            >
                {newsletter}
            </div>
            <hr
                css={css`
                    width: 100%;
                    height: 1px;
                    border: none;
                    background-color: #e9e9e9;
                `}
            />

            <Footer />
        </div>
    );
}

export default News;
