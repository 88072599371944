import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "../../slick.css";
import "../../slick-theme.css";
import Slider from "react-slick";

function P10(props) {
    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,

        slidesToShow: 1,
        pauseOnHover: true,
        slidesToScroll: 1,
        fade: true,
    };

    return (
        <div>
            <Header />
            <div
                css={css`
                    width: 980px;
                    height: 500px;
                    text-align: center;
                    margin: 0 auto;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                        height: 300px;
                    }
                    -webkit-animation: fadein 1s;
                `}
            >
                <Slider {...settings}>
                    <div
                        css={css`
                            background-image: url("images/P10/1.png");

                            background-repeat: no-repeat;
                            background-position: center;
                            background-size : cover;
                            -webkit-user-drag: none; //드래그 안되게
                            height: 500px;
                            margin-bottom: 6%;
                            @media screen and (max-width: 768px) {
                                height: 300px;
                                background-size: contain;
                            }
                        `}
                    />
                    <div
                        css={css`
                            background-image: url("images/P10/2.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size : cover;
                            -webkit-user-drag: none; //드래그 안되게
                            height: 500px;
                            margin-bottom: 6%;
                            @media screen and (max-width: 768px) {
                                height: 300px;
                                background-size: contain;
                            }
                        `}
                    />
                    <div
                        css={css`
                            background-image: url("images/P10/3.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size : cover;
                            -webkit-user-drag: none; //드래그 안되게
                            height: 500px;
                            margin-bottom: 6%;
                            @media screen and (max-width: 768px) {
                                height: 300px;
                                background-size: contain;
                            }
                        `}
                    />
                    <div
                        css={css`
                            background-image: url("images/P10/4.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size : cover;
                            -webkit-user-drag: none; //드래그 안되게
                            height: 500px;
                            margin-bottom: 6%;
                            @media screen and (max-width: 768px) {
                                height: 300px;
                                background-size: contain;
                            }
                        `}
                    />
                    <div
                        css={css`
                            background-image: url("images/P10/5.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size : cover;
                            -webkit-user-drag: none; //드래그 안되게
                            height: 500px;
                            margin-bottom: 6%;
                            @media screen and (max-width: 768px) {
                                height: 300px;
                                background-size: contain;
                            }
                        `}
                    />
                    <div
                        css={css`
                            background-image: url("images/P10/6.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size : cover;
                            -webkit-user-drag: none; //드래그 안되게
                            height: 500px;
                            margin-bottom: 6%;
                            @media screen and (max-width: 768px) {
                                height: 300px;
                                background-size: contain;
                            }
                        `}
                    />
                    <div
                        css={css`
                            background-image: url("images/P10/7.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size : cover;
                            -webkit-user-drag: none; //드래그 안되게
                            height: 500px;
                            margin-bottom: 6%;
                            @media screen and (max-width: 768px) {
                                height: 300px;
                                background-size: contain;
                            }
                        `}
                    />
                    <div
                        css={css`
                            background-image: url("images/P10/8.png");
                            background-repeat: no-repeat;
                            background-size : cover;
                            background-position: center;
                            -webkit-user-drag: none; //드래그 안되게
                            height: 500px;
                            margin-bottom: 6%;
                            @media screen and (max-width: 768px) {
                                height: 300px;
                                background-size: contain;
                            }
                        `}
                    />
                </Slider>
            </div>

            <Footer />
        </div>
    );
}

export default P10;
