import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // * BrowserRouter 불러오기
// import "./index.css";
import "./styles/globalStyles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import News from "./pages/News";
import Contact from "./pages/Contact";
import Recruitment from "./pages/Recruitment";
import P01 from "./pages/project/P01";
import P02 from "./pages/project/P02";
import P03 from "./pages/project/P03";
import P04 from "./pages/project/P04";
import P05 from "./pages/project/P05";
import P06 from "./pages/project/P06";
import P07 from "./pages/project/P07";
import P08 from "./pages/project/P08";
import P09 from "./pages/project/P09";
import P10 from "./pages/project/P10";
import NotFound from "./pages/NotFound";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/news" element={<News />} />
            <Route path="/01" element={<P01 />} />
            <Route path="/02" element={<P02 />} />
            <Route path="/03" element={<P03 />} />
            <Route path="/04" element={<P04 />} />
            <Route path="/05" element={<P05 />} />
            <Route path="/06" element={<P06 />} />
            <Route path="/07" element={<P07 />} />
            <Route path="/08" element={<P08 />} />
            <Route path="/09" element={<P09 />} />
            <Route path="/10" element={<P10 />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/recruitment" element={<Recruitment />} />
            <Route path="/*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
