import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function Recruitment() {
    return (
        <div
            css={css`
                width: 980px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-family: "NanumBarunGothic";
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            `}
        >
            <Header />
            <div
                css={css`
                width: 100%;
                margin: 0 auto;
                text-align: left;
                justify-content: center;
                font-size: 12px;
                color: #565656;
                margin-top: 50px;
                margin-bottom: 50px;
                line-height: 25px;
                padding-bottom: 10px;

                    -webkit-animation: fadein 1.5s;
                    @media screen and (max-width: 768px) {
                    width: 95%;
                   @keyframes fadein {
                     from {
                       opacity: 0;
                        }
                        to {
                            opacity: 1;

                }

          };

                `}
            >
                이력서와 간략한 포트폴리오(용량 20MB이하)를 아래 메일로 제출해주세요.
                <br />
                mail_ije@studioije.com
                <br />
            </div>
            <Footer />
        </div>
    );
}

export default Recruitment;
