/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ProjectThumnail from "./components/ProjectThumnail";
// import { Link } from "react-router-dom"; // * BrowserRouter 불러오기
import projectdata from "./project.json";
import "./styles/globalStyles.css";

function App() {
    const data = projectdata;
    const listProject = data.projectdata.map((data, index) => {
        return (
            <div key={index}>
                <ProjectThumnail data={data} imageName="images/P_01_SpaceOffice/00.jpeg" />

                {/*<Link css={[LinkText]} to={data.no}>
                    <ProjectThumnail data={data} imageName="images/P_01_SpaceOffice/00.jpeg" />
        </Link>*/}
            </div>
        );
    });

    return (
        <div
            className="App"
            css={css`
                width: 980px;
                margin: 0 auto;
                font-family: "NanumBarunGothic";
                font-style: normal;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            `}
        >
            <Header />
            <div
                css={css`
                -webkit-animation: fadein 2s;
                @keyframes fadein {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    };
                    `}
            >
                <div
                    css={css`
                        text-align: left;
                        font-size: 11px;
                        margin-left: 6px;
                        display: flex;
                    `}
                >
                    <div
                        css={css`
                            width: 40px;
                            font-weight: bold;
                        `}
                    >
                        no
                    </div>
                    <div
                        css={css`
                            width: 350px;
                            font-weight: bold;
                        `}
                    >
                        project
                    </div>
                    <div
                        css={css`
                            width: 150px;
                            font-weight: bold;
                        `}
                    >
                        title
                    </div>
                    <div
                        css={css`
                            width: 150px;
                            font-weight: bold;
                        `}
                    >
                        year
                    </div>
                    <div
                        css={css`
                            width: 150px;
                            font-weight: bold;
                        `}
                    >
                        type
                    </div>
                    <div
                        css={css`
                            width: 130px;
                            font-weight: bold;
                        `}
                    >
                        location
                    </div>
                </div>
                {listProject}
            </div>
            <Footer />
        </div>
    );
}

export default App;

/* const LinkText = css`
    text-decoration: none;
    color: black;
`; */
