import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function Contact({ match }) {
    return (
        <div
            css={css`
                width: 980px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-family: "NanumBarunGothic";

                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            `}
        >
            <Header />
            <div
                css={css`
                 margin: 0 auto;
                 font-size: 11px;
                    -webkit-animation: fadein 1.5s;
                  @keyframes fadein {
                  from {
                    opacity: 0;
                  }
                  to {
                    opacity: 1;
                  };

                `}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: left;
                        flex-wrap: wrap;
                    `}
                >
                    <div
                        css={css`
                            width: 400px;
                            height: 500px;
                            background-color: white;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-image: url("images/map.png");
                        `}
                    />

                    <span
                        css={css`
                            margin: auto 0 0 auto;
                            text-align: left;
                            /* margin-left: 15px; */
                            margin-right: 400px;
                            margin-top: 415px;

                            color: #414141;
                            @media screen and (max-width: 768px) {
                                margin-top: 10px;
                                width: 200px;
                            }
                        `}
                    >
                        <p css={[contents]}>서울시 용산구 효창원로 69길 20 </p>
                        <br />
                        <p css={[contents]}>02-6015-7184.</p>
                        <br />
                        <p css={[contents]}>mail_ije@studioije.com</p>
                        <br />
                        <p css={[contents]}>
                            <a
                                href="https://www.instagram.com/studioije_official/"
                                css={css`
                                    text-decoration: none;
                                    color: #a2a2a2;
                                    :hover {
                                        color: black;
                                    }
                                `}
                            >
                                instagram
                            </a>
                        </p>
                        <br />
                    </span>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;

const contents = css`
    width: 100%
    line-height: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
`;
