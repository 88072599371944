import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom"; // * BrowserRouter 불러오기

function header() {
    return (
        <div
            css={css`
                width: 980px;
                text-align: center;
                margin-top: 40px;
                margin: 0 auto;
                padding-top: 40px;
                padding-bottom: 10px;
                font-family: "NanumBarunGothic";
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            `}
        >
            <div
                css={css`
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                `}
            >
                <div
                    css={css`
                        text-align: left;
                    `}
                >
                    <Link
                        css={css`
                            text-decoration: none;
                            color: black;
                        `}
                        to="/"
                    >
                        <div
                            css={css`
                                width: 150px;
                                height: 30px;
                                margin-top: 25px;
                                background-color: white;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-image: url("images/ije_logo.svg");
                            `}
                        />
                    </Link>
                </div>
                <div
                    css={css`
                        width: 375px;
                        display: flex;
                    `}
                >
                    <div
                        css={css`
                            width: 75px;
                        `}
                    />
                    <div
                        css={css`
                            color: black;
                            margin-top: 36px;
                            width: 300px;
                            display: flex;
                            justify-content: space-between;
                            @media screen and (max-width: 768px) {
                                padding-right: 19px;
                            }
                        `}
                    >
                        <Link css={[headerDesign]} to="/news">
                            news
                        </Link>
                        <Link css={[headerDesign]} to="/">
                            project
                        </Link>
                        <Link css={[headerDesign]} to="/contact">
                            contact
                        </Link>
                        <Link css={[headerDesign]} to="/recruitment">
                            recruitment
                        </Link>
                    </div>
                </div>
            </div>
            <hr css={[divLine]} />
        </div>
    );
}

export default header;

const headerDesign = css`
    color: darkgray;
    font-size: 10px;
    line-height: 20px;
    text-decoration: none;
    :hover {
        color: black;
    }
`;

const divLine = css`
    width: 100%;
    height: 1px;
    border: none;
    background-color: #707070;
`;
