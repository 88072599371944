import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function Footer() {
    return (
        <div
            css={css`
                text-align: center;
                width: 980px;
                margin: 0 auto;
                font-size: 8px;

                font-family: "NanumBarunGothic";
                padding-top: 13px;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            `}
        >
            <hr css={[divLine]} />
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                `}
            >
                <div
                    css={css`
                        color: #515151;
                        text-align: left;
                        margin-right: 10px;
                    `}
                ></div>
                <div
                    className="qna"
                    css={css`
                        color: #515151;
                        text-align: right;
                        /* margin-right: 10px; */
                    `}
                >
                    All Reserved by STUDIOije
                    <a
                        href="https://www.instagram.com/studioije_official/"
                        css={css`
                            text-decoration: none;
                            margin-left: 7px;
                            color: #515151;
                            :hover {
                                color: black;
                            }
                        `}
                    >
                        @studioije_official
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;

const divLine = css`
    width: 100%;
    height: 1px;
    border: none;
    background-color: #707070;
`;
